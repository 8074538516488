import { Link } from "gatsby"
import React from "react"
import Logo from "../images/favicon-64x64.png"

const Header = () => (
  <header>
    <div
      style={{
        margin: `0 auto`,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <Link to="/">
        <img src={Logo} style={{margin: '0'}} alt="Manuel Cepeda logo" />
      </Link>
    </div>
  </header>
)

export default Header
